@font-face {
  font-family: 'Dazzed';
  font-weight: 400;
  src: url('./assets/fonts/Dazzed-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 400;
  font-style: italic;
  src: url('./assets/fonts/Dazzed-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 500;
  src: url('./assets/fonts/Dazzed-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 600;
  src: url('./assets/fonts/Dazzed-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Dazzed';
  font-weight: 700;
  src: url('./assets/fonts/Dazzed-Bold.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Dazzed', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
