/* These styles are used if a demo specific stylesheet is not present */

*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
html {
  background-color: #f9f8f0;
}

form {
  max-width: 34rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.checkout-form label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
}

.checkout-form button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  gap: 4px;

  width: 200px;
  height: 56px;

  background: #cdb4fe;

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

/* .checkout-form button at mobile should have a width of 100% */
@media (max-width: 768px) {
  .checkout-form button {
    width: 100%;
  }
}

form.checkout-form {
  max-width: 28rem;
}
.checkout-form button:hover {
  cursor: pointer;
  background-color: #c09fff;
  transform: translateY(-1px);
}

.checkout-form button[disabled] {
  opacity: 0.6;
  color: rgba(0, 0, 0, 0.38);
  background-color: rgba(0, 0, 0, 0.08);
}

.checkout-form input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 10px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
  border: 1px solid #c4c4c4;
}

.checkout-form input::placeholder {
  color: #9d9d9e;
  font-weight: 400;
}

.result,
.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.error {
  color: #e4584c;
}

.result {
  color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 18px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;

  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal {
  padding: 0;
}

/* this will be removed on parts kit as part of a code refactoring */
.page-title {
  font-size: 30px;
}
.user-warn {
  max-width: 34rem;
  margin: 0 auto;
}
.button-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  gap: 4px;
  min-width: 140px;
  height: 56px;
  background: #cdb4fe;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 100px;
  font-weight: 500;
}

.buton-primary,
.medium {
  width: 170px;
  height: 40px;
}

button.button-primary:hover {
  cursor: pointer;
  background-color: #c09fff;
}

.button-primary :disabled {
  opacity: 0.6;
  color: rgba(0, 0, 0, 0.38);
  background-color: rgba(0, 0, 0, 0.08);
}

input[type='text'],
input[type='email']
/* react-dates override */
.DateInput_input,
.DateInput {
  border-radius: 10px !important;
}

.SingleDatePickerInput__withBorder {
  border-radius: 10px !important;
}

#dateOfBirth {
  border-radius: 10px !important;
  height: 3rem !important;
  width: 100%;
}

#dateOfBirth::placeholder {
  padding: 12px;
  color: #000;
}

.SingleDatePicker__block {
  width: 100%;
}

label > span {
  color: #000 !important;
  text-transform: lowercase;
  font-size: 14px !important;
}

label > span::before {
  content: '(';
  color: #000;
}

label > span::after {
  content: ')';
  color: #000;
}

button[type='submit'] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  gap: 4px;
  width: 200px;
  height: 56px;
  background: #cdb4fe;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

button[type='submit']:hover {
  cursor: pointer;
  background-color: #c09fff;
}

/* .checkout-form button at mobile should have a width of 100% */
@media (max-width: 768px) {
  button[type='submit'] {
    width: 100%;
  }
}
